import React, { useEffect, useReducer, useState } from "react";
import BaseAuth from "./BaseAuth";
import {
  getCountryList,
  requestApplySignupCode,
  userRequestOTP,
  userRequestOTPSignUp,
} from "../../Utilities/controller";
import { func } from "../../Utilities/logFunc";
import Loader from "../Common/Loader";
import { url } from "../../Utilities/url";
import { errors } from "../../Utilities/error";
import { resolvePath, useLocation, useNavigate } from "react-router";
import { routes } from "../../Utilities/routesFb";
import { INITIAL_STATE, loginReducer } from "./loginReducer";
import LandingPage from "../LandingPage/LandingPage";
import { INITIAL_STATE_REG, registerReducer } from "./SignupReducer";
import Select from "react-select";
import DropDown from "../Common/DropDown";
import CountryDropDown from "../Common/CountryListDropDown";
import { logout } from "../../Utilities/logout";
import { toast } from "react-toastify";
import LoginLandingPage from "../LandingPage/LoginLandingPage";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { encryptKey } from "../../Utilities/encryptDecrypt";
import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents"; 
import axios from "axios";
const Login = () => {
  func("login", "login page");
  const [countryList, setCountryList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loginInput, dispatchInput] = useReducer(loginReducer, INITIAL_STATE);
  const [registerInput, dispatchRegisterInput] = useReducer(
    registerReducer,
    INITIAL_STATE_REG
  );
  const [error, setError] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [appliedCouponCode, setAppliedCouponCode] = useState(undefined);
  const [couponError, setCouponError] = useState("");
  const [couponSuccess, setCouponSuccess] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [onAgree, setOnAgree] = useState(false);
  const [tncError, setTncError] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [index, setIndex] = useState(0);
  const [pin, setPin] = useState();
  const [pinError, setPinError] = useState();
  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState(false)
  const location = useLocation()
  const {state} = location
  // console.log(state)
  const [active, setActive] = useState(state || 0)
  const { t } = useTranslation();

  const translatedText = t("login.i_agree_to_mobily", {
    interpolation: { escapeValue: false },
  });
  const getGeoInfo = async () => {
    let data = {};
    await axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        data = response.data;
        // console.log(
        //       data.ip,
        //       data.country_name,
        //     data.country_calling_code,
        //      data.city,
        //     data.timezone)
        setCountryCode(data?.country_calling_code);
      })

      .catch((error) => {
        // console.log(error);
      });
    return data;
  };
  const fetchCountry = async () => {
    setLoader(true);
    try {
      const data = await getGeoInfo();
   
      const res = await getCountryList();
      if (res) setCountryList((prev) => res?.list);
      // setCountryOption(prev=> res?.list[2].dialingCode)
      // console.log(data?.country_calling_code)
      let cnty = data?.country_calling_code || "+973"
      let index = null;
      res?.list?.filter((item, ind) => {
        if (item?.dialingCode == cnty) {
          index = ind;
          return index;
        }
      });
      // console.log(index)

      if (index == -1 || index == null){
        res?.list?.filter((item, ind) => {
          if (item?.dialingCode == "+973") {
            index = ind;
            return index;
          }
        });
      }
      // console.log(index)
      setIndex(index);
      dispatchInput({
        type: "CHANGE_INPUT",
        payload: {
          name: "countryOption",
          value: res?.list?.[index]?.dialingCode,
        },
      });
      dispatchInput({
        type: "CHANGE_INPUT",
        payload: { name: "code", value: res?.list?.[index]?._id },
      });
      dispatchRegisterInput({
        type: "CHANGE_INPUT",
        payload: {
          name: "countryOption",
          value: res?.list?.[index]?.dialingCode,
        },
      });
      dispatchRegisterInput({
        type: "CHANGE_INPUT",
        payload: { name: "code", value: res?.list?.[index]?._id },
      });
    } catch (error) {
      func("error", error);
    }
    setLoader(false);
  };
  useEffect(() => {
    fetchCountry();
  }, []);
  const onChangeHandler = (e) => {
    setError("")
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };
  const onSignupChangeHandler = (e) => {
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };
  const loginHandler = (e) => {
    e.preventDefault();
    setError("")
    setPinError("")
    setIsBusy(true)
    if (!loginInput?.mobileNumber || loginInput?.mobileNumber?.length == 0) {
      setError(t('error_enter_phone'));
    } 
    else if (loginInput?.countryOption == "+91") {
      if (loginInput?.mobileNumber.length != 10) {
        setIsBusy(false)
        setError(t('error_enter_valid_phone'));
      } else if (loginInput?.pin.length < 6) {
        setPinError(t('error_pin_length'));
        setIsBusy(false)
      } else {
        setIsBusy(false)
        callToOTPRequestAPI();
      }
    } else {
      if (loginInput?.mobileNumber.length < 8 || loginInput?.mobileNumber.length > 14 ) {
        setError(t('error_enter_valid_phone'));
      } else if (loginInput?.pin.length < 6) {
        setIsBusy(false)
        setPinError(t('error_pin_length'));
      } else {
        
        callToOTPRequestAPI();
        setIsBusy(false)
      }
    }
    
    // else if (loginInput?.mobileNumber?.length != 10) {
    //   setError(errors?.error_enter_valid_phone);
    // } else {
    //   callToOTPRequestAPI();
    // }
    // const onSignupChangeHandler = (e) => {
    //   dispatchRegisterInput({ type: 'CHANGE_INPUT', payload: { name: e.target.name, value: e.target.value } })
    // }
    // const loginHandler = (e) => {
    //   e.preventDefault()
    //   if (!loginInput?.mobileNumber || loginInput?.mobileNumber?.length == 0) {
    //     setError(errors.error_enter_phone)
    //   }
    //   else if (loginInput?.mobileNumber?.length != 10) {
    //     setError(errors?.error_enter_valid_phone)
    //   }
    //   else {
    //     callToOTPRequestAPI();

    //   }
    // }
  }

  const callToOTPRequestAPI = async () => {
    setIsBusy(true)
    const encPin = await encryptKey(loginInput?.pin);
    var payload = {
      type: "otpRequest",
      username: loginInput?.mobileNumber,
      phoneCode: loginInput?.countryOption,
      country: loginInput?.code,
      pin: encPin,
    };
    // const encPin = encryptKey(e.target.value.slice(0,6))

    // localStorage.setItem("loginInfo", JSON.stringify(payload));

    setLoading(true);
    try {
      const res = await userRequestOTP(payload);
      console.log(resolvePath)
        localStorage.setItem("user-id", res?.item?.id);
        localStorage.setItem("country", res?.item?.address?.country);
        localStorage.setItem("token", res?.token);
        localStorage.setItem(
          "d11_username",
          res?.item?.gamerjiName
        );
        setIsBusy(false)
        if (res?.item?.isDetailsFilled)
        {
          // console.log(localStorage.getItem('code'))
          if (localStorage.getItem('code'))
          {
            window.location.href = "/" + routes.home + `?code=${localStorage.getItem('code')}`;
            
          }
           
            else 
            window.location.href = "/" + routes.home ;
        } 
        else window.location.href = "/avatar";
        //analytics Tracking
        eventTracking(events.LOGIN.LOGIN,{
          MG_MobileNumber: loginInput?.mobileNumber,
          MG_CountryCode: loginInput?.countryOption,
          MG_UserID:res?.item?._id
        });
      // if (res?.success) {
      //   navigate("/" + routes.otp, { state: loginInput });
      // } else {
      //   setError(res?.response?.data?.errors?.[0]?.msg);
      // }
    } catch (error) {
      setIsBusy(false)
      // console.log("userRequestOTP error", error?.response.data);
      if (error?.response?.data?.message) {
        setError(error?.response?.data?.message);
      } else if (error?.response?.data?.errors?.[0].msg) {
        setError(error?.response?.data?.errors[0].msg);
      }
      // console.log(error?.response?.data?.errors[0].msg)
      // setPinError(error?.response?.data?.errors[0].msg)
      // setError(error?.response?.data?.errors[0].msg);
    }
 
    setLoading(false);
  };

  const handlerSelect = (e) => {
    setCountryCode(e);
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: "code", value: e?._id },
    });
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: "countryOption", value: e?.dialingCode },
    });
  };
  const handlerSelectRegister = (e) => {
    eventTracking(events.country_code,{
      MG_Country_Code: e?.dialingCode,
    });
    setCountryCode(e);
    func("onChange", )
    setCouponError("")
    setCouponSuccess("")
    setCouponCode("")
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: "code", value: e?._id },
    });
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: "countryOption", value: e?.dialingCode },
    });
  };
  // request OTP after login


  // Registration
  const checkValidation = (e) => {
    e.preventDefault();
    setIsBusy(true)
    if (
      !registerInput?.mobileNumber ||
      registerInput?.mobileNumber?.length == 0
    ) {
      setSignupError(t('error_enter_phone'));
      setIsBusy(false)
      return;
    } else {
      if (registerInput?.countryOption == "+91") {
        if (registerInput?.mobileNumber.length != 10) {
          setSignupError(t('error_enter_valid_phone'));
          setIsBusy(false)
          return;
        } else {
          setError("");
          setIsBusy(false)
        }
      } else {
        if (registerInput?.mobileNumber.length < 8 || registerInput?.mobileNumber.length > 14) {
          setSignupError(t('error_enter_valid_phone'));
          setIsBusy(false)
          return;
        }
      }
    }

    if (onAgree) {
      setTncError("");
      
      callToOTPRequestAPISignup();
      setIsBusy(false)
    } else {
      setIsBusy(false)
      setTncError(t('error_agree_terms_and_conditions'));
    }
  };

  // Update the state based on whether the checkbox is checked or not
  const handleCheckboxChange = (e) => {
    setOnAgree(e.target.checked);
  };

  // call signup API
  const callToOTPRequestAPISignup = async () => {
    if (!registerInput?.countryOption) {
      setSignupError(t('error_select_country_code'));
      return;
    }
    var payload = {
      type: "otpRequest",
      username: registerInput?.mobileNumber,
      phoneCode: registerInput?.countryOption,
      country: registerInput?.code,
      code: couponCode,
      platform:"webapp"
    };
    localStorage.setItem('country',registerInput?.code )
    if (appliedCouponCode) {
      payload.code = couponCode;
    }
    eventTracking(events.REGISTER,{
      MG_Country_Code:registerInput?.countryOption,
      MG_Mobile_Number:registerInput?.mobileNumber, 
      MG_Signupcode:couponCode
    });

    setLoading(true);
    func("signup payload", payload);
    try {
      const res = await userRequestOTPSignUp(payload);
      func("register res", res);
      if (res?.success) {
        navigate("/" + routes.signupotp, { state: registerInput });
      } else {
        setSignupError(res?.response?.data?.errors[0]?.msg);
      }
    } catch (error) {
      func("signup error", error);
      if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
      logout()
            toast.error(error?.response?.data?.errors?.[0]?.msg)
      setSignupError(error?.response?.data?.errors?.[0]?.msg);
    }
    setLoading(false);
  };

  // call coupon apply API
  const applySingupCode = async (e) => {
    e.preventDefault();
    if (couponCode == "") {
      setCouponError(t('error_enter_coupon_code'));
    } else {
      setLoading(true);
      try {
        const res = await requestApplySignupCode({
          code: couponCode,
          type: "signupCode",
          country:registerInput.code
        });
        func("applySingupCode res", res);
        // setAppliedCouponCode(res.item)
        if (res?.success) {
          eventTracking(events.APPLY_SIGNUP_CODE,{
            MG_CouponCode: couponCode,
          });
          setCouponError('')
          setCouponSuccess("Signup Code Applied Successfully!");
        }
        if (res?.response.data.errors) {
          setCouponError(res?.response?.data?.errors?.[0]?.msg);
        }
      } catch (error) {
        func("error", error);
        if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
        logout()
        setSignupError(error?.response?.data?.errors?.[0]?.msg);
      }
      setLoading(false);
    }
  };

  const customStyles = {

    // menu: (defaultStyles, state) => ({
    //   ...defaultStyles,
    //   // backgroundColor: "red",
    //   minHeight:'15rem',
    //   backgroundColor: "var(--secondary-color-emphasis)",
    // }),

    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected
        ? "var(--secondary-color-emphasis)!important"
        : "purple",
        height:'2.5rem',
        "&:hover" : {
          color:'var(--accent-color)'
        },
        padding:"0.65rem 1.25rem",
        borderRadius:'20px'
      
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "var(--secondary-color-emphasis)",
      padding: "0.5rem",
      border: "none",
      boxShadow: "none",
      borderRadius: "50px",
      display: "flex",
      alignItems: "center",
      caretColor:'transparent',
      minHeight:'3.75rem'
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      width: "4.7rem",
      marginRight: "0.75rem",
      padding: "0.35rem",
     
    }),
    indicatorsSeparator: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      display: "none",
    }),
    indicatorContainer: (defaultStyles) => ({
      ...defaultStyles,
      padding: "0",
      backgroundColor: "red",
    }),

    input: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "1.85rem",
    }),
    dropdownIndicator:(defaultStyles) => ({
    
      color:'var(--color-white)',
      width:'1.5rem',
      height:'1.5rem',
      padding:'0',
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
    })
  };
  const onChangePin = (e) => {
    setPinError("")
    let text = "*";
    setPin(e.target.value);
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <LoginLandingPage>
          <BaseAuth>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-sm-9">
                <ul
                  className="nav nav-pills  d-flex justify-content-between auth-nav"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation" style={{width:'50%'}}>
                    <button
                      className={`nav-link ${active === 0 ? 'active' : ""}`}
                      id="login-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#login-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="login-tab-pane"
                      aria-selected="true"
                      onClick={()=>{
                        eventTracking(events.LOGIN_TAB);
                      }}
                      style={{width:'100%'}}
                    >
                      {t("login.login")}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation" style={{width:'50%'}}>
                    <button
                      className={`nav-link ${active === 1 ? 'active' : ""}`}
                      id="register-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#register-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="register-tab-pane"
                      aria-selected="false"
                      style={{width:'100%'}}
                      onClick={()=>{
                        eventTracking(events.REGISTER_TAB);

                      }}
                    >
                      {t("login.register")}
                    </button>
                  </li>
                </ul>
                <div className="tab-content auth-content">
                  {/* LOGIN TAB*/}
                  <div
                    className={`tab-pane fade ${active === 0 ? 'show active' : ""}`}
                    id="login-tab-pane"
                    role="tabpanel"
                    aria-labelledby="login-tab"
                    tabindex="0"
                  >
                    <form className="row">
                      <div className="col-4">
                        <label
                          for="inputCoutryCode"
                          className="form-label text-nowrap"
                        >
                          {t("login.country_code")}
                        </label>

                        <Select
                          styles={customStyles}
                          name="countryOption"
                          // value={countryCode}
                          defaultValue={countryList?.[index]}
                          options={countryList}
                          onChange={(e) => handlerSelect(e)}
                          getOptionLabel={(ele) => (
                            <div
                              className="d11-menu"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span class="icon">
                                <img
                                   class="me-1"
                                  // style={{
                                  //   borderRadius: "50%",
                                  //   width: "24px",
                                  //   height: "24px",
                                  //   marginRight: "5px",
                                  // }}
                                  src={url.imageUrl + ele?.flag?.default}
                                  alt=""
                                />
                              </span>{" "}
                              {ele?.dialingCode}
                            </div>
                          )}
                          
                        />
                       {/* <CountryDropDown options={countryList} /> */}
                      </div>
                      
                      <div className="col-8">
                        <label for="inputMobileNumber" className="form-label">
                        {t("login.mobile_number")}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="inputMobileNumber"
                          placeholder={t("login.enter_mobile_number")}
                          name="mobileNumber"
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={(e) => onChangeHandler(e)}
                          maxLength={14}
                          value={loginInput?.mobileNumber.slice(0, 14)}
                        />
                      </div>
                      <div className="col-12">
                        <label for="inputMobileNumber" className="form-label">
                          {t("login.enter_pin")}
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          style={{
                            // "-webkit-text-security": "circle",
                            fontSize: "1.1rem",
                          }}
                          id="inputPin"
                          placeholder={t("login.enter_pin")}
                          name="pin"
                          onKeyDown={(e) =>
                            {
                              const isNumericKey = !isNaN(parseInt(e.key));
                              const isControlKey =
                                e.key === "Backspace" || e.key === "Delete" || e.key === "ArrowLeft" || e.key === "ArrowRight";
                              if (!isNumericKey && !isControlKey) {
                                e.preventDefault();
                              }
                            }
                          }
                          onChange={(e) => onChangePin(e)}
                          maxLength={6}
                          value={pin}
                        />
                      </div>
                      <div className="col-12 d-grid">
                        <button
                          className="btn btn-primary btn-arrow"
                          disabled={isBusy}
                          onClick={(e) => loginHandler(e)}
                        >
                          {t("login.login")}
                        </button>
                        {error && (
                          <p className="error" style={{ textAlign: "center" }}>
                            {error}
                          </p>
                        )}
                        {pinError && (
                          <p className="error" style={{ textAlign: "center" }}>
                            {pinError}
                          </p>
                        )}
                        <div className="text-center mt-3">
                          <p className="mb-1">{t("login.forget_your_pin")}</p>
                          <p>
                            <Link to={`/forget-pin`}>{t("login.reset")}</Link>{" "}
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* Registertion Tab */}
                  <div
                    className={`tab-pane fade ${active === 1 ? 'show active' : ""}`}
                    id="register-tab-pane"
                    role="tabpanel"
                    aria-labelledby="register-tab"
                    tabindex="0"
                  >
                    <form className="row">
                      <div className="col-4">
                        <label
                          for="inputCoutryCode"
                          className="form-label text-nowrap"
                        >
                          {t("login.country_code")}
                        </label>

                        <Select
                          styles={customStyles}
                          name='countryOption'
                          // value={countryCode}
                          defaultValue={countryList?.[index]}
                          options={countryList}
                          onChange={(e) => handlerSelectRegister(e)}
                          getOptionLabel={ele => (
                            <div  className="d11-menu" style={{ display: 'flex', alignItems: 'center' }}>
                              <span class="icon" >
                                <img  class="icon-16 me-1 d11-country-flag"
                                
                                src={url.imageUrl + ele?.flag?.default} alt="" />
                              </span>{"  "}<span >{ele?.dialingCode}</span>
                            </div>
                          )}
                        />
                      </div>
                      <div className="col-8">
                        <label for="inputMobileNumber" className="form-label"
                        >
                          {t("login.mobile_number") || "Mobile Number"} </label>
                        
                        <input
                          type="number"
                          className="form-control"
                          id="inputMobileNumber"
                          placeholder={t("login.enter_mobile_number")|| "Enter Mobile Number"}
                          name='mobileNumber'
                          onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e) => {
                           
                            onSignupChangeHandler(e)
                           
                          }}
                          maxlength={14}
                          value={registerInput?.mobileNumber.slice(0,14)}
                        />
                      </div>
                      <div className="col-md-12">
                        <label for="inputCouponCode" className="form-label"
                        >
                           {t("login.coupon_code") || "Coupon Code"}
                          </label>
                        <div className="couponCode-block">
                          <input
                            type="text"
                            className="form-control"
                            id="inputCouponCode"
                            placeholder={t("login.enter_coupon_code") || "Enter Coupon Code"}
                            name='couponCode'
                            onChange={(e) => setCouponCode(prev => e.target.value)}
                            value={couponCode}
                          />
                          {
                            couponSuccess ? <button
                            className="btn btn-primary"
                            // style={{ position: 'absolute', right: '0', top: '0', width: '120px', height: '100%' }}
                            onClick={(e) =>{
                              eventTracking(events.REMOVE_SIGNUP_CODE,{
                                MG_CouponCode: couponCode,
                              });
                              e.preventDefault()
                              setCouponError('')
                              setCouponCode('')
                              setCouponSuccess('')
                            }
                            }
                          >
                            {t("login.remove") || "Remove"}
                          </button> : 
                          <button
                          className="btn btn-primary"
                          // style={{ position: 'absolute', right: '0', top: '0', width: '120px', height: '100%' }}
                          onClick={(e) => applySingupCode(e)}
                        >
                          {t("login.apply") || "Apply"}
                        </button>
                          }
                          
                        </div>
                        {couponError && <p className='error'>{couponError}</p>}
                        {couponSuccess && <p style={{color:"green", marginTop:'20px', marginLeft:'10px'}}>{couponSuccess}</p>}
                      </div>
                      <div className="col-12">
                        <div className="form-check d-flex justify-content-start">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="gridCheck"
                            name="checkBox"
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-terms " for="gridCheck">
                          <span
                              dangerouslySetInnerHTML={{
                                __html: translatedText,
                              }}
                            />
                            {/* I agree to D11’s <Link to={'/'+ routes.termsAndCondition} target="_blank"><strong>Terms & Conditions</strong> {" "} </Link> and {" "}
                            <Link to={'/' + routes.policy}  target="_blank"><strong> Privacy Policy </strong></Link> */}
                          </label>
                        </div>
                        {tncError && <p className='error'>{tncError}</p>}

                      </div>
                      <div className="col-12 d-grid">
                        <button 
                        type="submit" 
                        className="btn btn-primary btn-arrow" 
                        onClick={(e) => checkValidation(e)}
                        disabled={isBusy}
                        >
                        {t("login.register") || "Register"}
                        </button>
                      </div>
                      {func("sigin", error)}
                      {
                        signupError && <p className='error' style={{ textAlign: 'center' }}>{signupError}</p>
                      }

                    </form>


                  </div>
                </div>
              </div>
            </div>
          </BaseAuth>
        </LoginLandingPage>
      )}
    </>
  );
};
export default Login;
