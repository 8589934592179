import React, { useEffect, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import {
  createTangerineSubscription, createTangerineSubscriptionNew,
} from "../../Utilities/controller";
import "../../assets/css/PaymentStatus.css";
import { Modal } from "react-bootstrap";
import { func } from "../../Utilities/logFunc";
import { toast } from "react-toastify";
import Loader from "../Common/Loader";
import { useTranslation } from "react-i18next";

// import Pending from "../../assets/images/pending-payment.png";
// import Fail from "../../assets/images/fail-payment.png";
// import Success from "../../assets/images/success-payment.png";

const TangerinePaymentStatus = (props) => {
  const navigate = useNavigate();
  let { id, trxId } = useParams();
  const [searchParams] = useSearchParams();
  const [transactionData, setTansactionData] = useState({});
  // const [show, setShow] = useState(true);
  const {t} = useTranslation()
  const [loader, setLoader] = useState(false);
  const handleClose = () => {
    props.closePaymentPopUp();
    // navigate("/home");
    window.location.href = '/home'
  };
  useEffect(() => {
    // let token = localStorage.getItem('token')
    // if(token)
    // fetchCoinStoreData();
    // else{
    //   fetchNewCreateTan()
    // }
    let timer = setTimeout(() => {
      fetchCoinStoreData();
    }, 6000);
    return () => {
      clearTimeout(timer);
    };
  },[]);

  const fetchNewCreateTan = async()=>{
try{
  let body = {
    "packName": props.tangerineParams.packName,
    "planName": props.tangerineParams.planName,
    "userId": props.tangerineParams.userId,
    "providerName": props.tangerineParams.providerName,
    "providerType": props.tangerineParams.providerType,
    "userIdType": props.tangerineParams.userIdType,
    "status": props.tangerineParams.status,
    "signature": props.tangerineParams.signature,
    "extendedParameters": {
      "correlatorId": props.tangerineParams.correlatorId
    }
  };
  const res = await createTangerineSubscriptionNew(body);
  console.log(res)
if (res.data.success) {
  localStorage.setItem('token', res?.data?.token)
  localStorage.setItem('country', res?.data?.country)

    window.location.href = '/avatar'
  setLoader(false);
} else {
}
}
catch(err){
  setLoader(false);
  console.log(err)
}

  }

  const fetchCoinStoreData = async () => {
    // console.log(props.tangerineParams)
    setLoader(true);
    try {
      let body = {
        "packName": props.tangerineParams.packName,
        "planName": props.tangerineParams.planName,
        "userId": props.tangerineParams.userId,
        "providerName": props.tangerineParams.providerName,
        "providerType": props.tangerineParams.providerType,
        "userIdType": props.tangerineParams.userIdType,
        "status": props.tangerineParams.status,
        "signature": props.tangerineParams.signature,
        "extendedParameters": {
          "correlatorId": props.tangerineParams.correlatorId
        }
      };
      const res = await createTangerineSubscription(body);
      if (res.data.success) {
        setTansactionData(res?.data?.data);
        func("transaction data 40 ****", res?.data?.data);
        func("**********************", res?.data?.data?.paymentStatus);
        setLoader(false);
      } else {
      }
    } catch (error) {
     
      if(error?.response?.data?.errors?.[0]?.code == 'middlewares.token_parser.validation.token_invalid'){
        console.log("yessss")
        fetchNewCreateTan()
      }
      // toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
  };
  //  if(loader) <Loader />
  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="content text-center">
          <div class="row justify-content-center">
            <div class="col-12">
              <div className="pg_status_main_component">
                {/* <ToastContainer /> */}

                <div className="pg_status_body">
                  {/* <div className="pg_status_title_component">
                    <div className="pg_title">Payment Status</div>
                </div> */}
                  <div className="pg_ps_status_component">
                    {/* <h3>Payment Successful</h3> */}
                    {/* <h5>Your are now subsccribed user</h5> */}
                    <div
                      style={{ color: "#fff", padding: "20px" }}
                      className={
                        transactionData?.paymentStatus === "SUCCESS"
                          ? "pg_status_background_success"
                          : transactionData?.paymentStatus === "FAILED"
                            ? "pg_status_background_failed"
                            : transactionData?.paymentStatus === "DECLINED" ||
                              transactionData?.paymentStatus === "CANCELLED"
                              ? "pg_status_background_failed"
                              : "pg_status_background_pending"
                      }
                    >
                      {/* Status Icon */}
                      {/* <img className="pg_status_icon" src={
                            transactionData?.paymentStatus === 'SUCCESS' ? Success
                                : transactionData?.paymentStatus === 'FAILED' ? Fail
                                    : transactionData?.paymentStatus === 'DECLINED' || transactionData?.paymentStatus === 'CANCELLED' ? Fail
                                        : Pending
                        } /> */}

                      {/* Title */}
                      <div className="pg_status_title">
                        {loader
                          ? "Processing"
                          : transactionData?.paymentStatus === "SUCCESS"
                            ? t('premium.success')
                            : transactionData?.paymentStatus === "FAILED"
                              ? t('premium.low_balance')
                              : transactionData?.paymentStatus === "DECLINED" ||
                                transactionData?.paymentStatus === "CANCELLED"
                                ? t('premium.user_cancelled')
                                : t('premium.processing')}
                      </div>

                      {/* Amount */}
                      {transactionData?.paymentStatus && (
                        <>
                          <div className="pg_status_amount">
                            {transactionData?.currency?.symbol}{" "}
                            {transactionData?.amount}
                          </div>

                        
                          <div className="pg_status_date">
                            {new Date(
                              transactionData?.paymentTimestamp
                            ).toLocaleString()}
                          </div>

                          <div className="pg_status_transaction">
                            Transaction ID : {transactionData?.transactionId}
                          </div>
                        </>
                      )}

                      {transactionData?.paymentStatus === "SUCCESS" ? (
                        <div className="pg_status_transaction">
                           { t('premium.your_subscription_success')}
                        </div>
                      ) : transactionData?.paymentStatus === "FAILED" ? (
                        <div className="pg_status_transaction">
                            { t('premium.cant_proceeds')}
                        </div>
                      ) : (
                        t('premium.transaction_inprogess')
                      )}
                    </div>
                    <button
                      // className={`btn btn-primary main-btn next mt-4 mb-4 ml-2 
                      // ${transactionData?.paymentStatus !== 'SUCCESS' && transactionData?.paymentStatus !== "FAILED" ? "disabled" : ""}
                      // `}
                      className={`btn btn-primary main-btn next mt-4 mb-4 ml-2 
                   
                      `}
                      style={{ width: "100%", border: "1px solid" }}
                      onClick={() => {
                        props.closePaymentPopUp();
                        // navigate("/home");
                        window.location.href = '/home'
                      }}
                    >
                     {t('premium.done')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default TangerinePaymentStatus;
